
import Vue from 'vue'
import { KwikMediumContainer } from 'kwik-vue'
import { RouteNames } from '@/router/names'
import { readLastStats } from '../store/getters'
import { dispatchGetStats } from '../store/actions'
import { readIsInbound, readIsManager, readIsOutbound, readIsProduction } from '@/modules/match/store/getters'

export default Vue.extend({
  name: 'FFDashBoard',
  components: { KwikMediumContainer },
  data () {
    return {
      options: {} as any,
      loading: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Dashboard', disabled: false }
      ]
    }
  },
  computed: {
    stats () {
      return readLastStats(this.$store)
    },
    isInbound (): boolean {
      return readIsInbound(this.$store)
    },
    isManager (): boolean {
      return readIsManager(this.$store)
    },
    isOutbound (): boolean {
      return readIsOutbound(this.$store)
    },
    isProduction (): boolean {
      return readIsProduction(this.$store)
    }
  },
  methods: {
    async openBalance () {
      await this.$router.push(
        {
          name: RouteNames.FF_BANKING
        }
      )
    },
    async openBatches () {
      await this.$router.push(
        {
          name: RouteNames.FF_BATCHES
        }
      )
    },
    async openScheduler () {
      await this.$router.push(
        {
          name: RouteNames.FF_PRODUCTION_ORDERS
        }
      )
    },
    async openOfferings () {
      await this.$router.push(
        {
          name: RouteNames.FF_OFFERINGS
        }
      )
    },
    async openCustomerTransactions () {
      await this.$router.push(
        {
          name: RouteNames.FF_CUSTOMER_TRANSACTIONS
        }
      )
    },
    async openSupplierTransactions () {
      await this.$router.push(
        {
          name: RouteNames.FF_SUPPLIER_TRANSACTIONS
        }
      )
    }
  },
  async mounted () {
    await dispatchGetStats(this.$store)
  }
})
